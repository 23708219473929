import React from "react";
import Artykul from "../components/artykul";
import { graphql } from "gatsby";
import { GatsbyImage } from "gatsby-plugin-image";
import obrazekArtykulu from "../images/system-crm-w-zarzadzaniu-vlog.png";
import Lightbox from "../components/lightBox";
import ResponsiveEmbed from "react-responsive-embed";
import { Link } from "gatsby";
import * as styles from "./index.module.css";


export const query = graphql`
  {
    zdjecie1: file(relativePath: { eq: "szanse-sprzedazy-crm.png" }) {
      childImageSharp {
        gatsbyImageData(layout: FULL_WIDTH)
      }
    }
    zdjecie2: file(relativePath: { eq: "aktywnosc-min.png" }) {
      childImageSharp {
        gatsbyImageData(layout: FULL_WIDTH)
      }
    }
    zdjecie3: file(relativePath: { eq: "shaking-hands-3091906_1920.jpg" }) {
      childImageSharp {
        gatsbyImageData(layout: FULL_WIDTH)
      }
    }
  }
`;

const Aplikacjacrm = ({ data }) => {
  return (
    <Artykul
      title="Rozbudowa kanału sprzedaży pośredniej za pomocą systemu CRM"
      keywords={["sprzedaż pośrednia"]}
      articleImage={obrazekArtykulu}
      articleImageAlt="Rozbudowa kanału sprzedaży pośredniej za pomocą systemu CRM"
      metaTitle="Rozbudowa kanału sprzedaży pośredniej za pomocą systemu CRM"
      metaDescription="System CRM może być przydatnym narzędziem do budowy kanału sprzedaży pośredniej. W niniejszym materiale omawiamy, w jaki sposób. ✅ 
      CRM a sprzedaż pośrednia • Video 2020"
    >
      <br />
      <p>
        Do klientów końcowych dotrzeć można wieloma różnymi kanałami. Oprócz
        sprzedaży bezpośrednio do nich, Twoja firma może również posiłkować się
        siecią podmiotów, która będzie prowadzić sprzedaż pośrednią Twoich
        produktów. To świetny sposób na wzrost firmy i skalowanie sprzedaży.
      </p>
      <br />
      <ResponsiveEmbed
        src="https://www.youtube.com/embed/26Meh64YxP4"
        allowFullScreen
        ratio="16:9"
      />
      <br />
      <p>
        Istnieją rozwiązania informatyczne, które mogą wesprzeć Twoją firmę w
        budowie kanału sprzedaży pośredniej oraz jego rozwoju i optymalizacji. W
        niniejszym materiale zaprezentujemy, jak można do tego wykorzystać
        system CRM.
      </p>
      <br />
      <h2>Kanał sprzedaży pośredniej</h2>
      <p>
        Model ten przewiduje połączenie dostawcy z odbiorcą za pomocą strony
        trzeciej. Rola takich podmiotów może się nieco różnić, w zależności od
        branży, rynku lub charakteru produktu. Proces tworzenia takiego kanału
        zakłada, że ​​Twoim celem nie jest zdobycie klienta końcowego, ale
        podmiotów, które się tym zajmą.
      </p>

      <p>
        W zależności od tego, jaki charakter ma Twój produkt, różne firmy mogą
        zostać Twoimi partnerami. Inaczej też będzie wyglądała współpraca
        pomiędzy wami. Oto przykładowe rodzaje współpracy w sprzedaży
        pośredniej:
      </p>
      <br />
      <h3>Współpraca dystrybucyjna</h3>
      <p>
        Współpraca tego typu obejmuje dystrybutorów dla nieskomplikowanych
        produktów, których sprzedaż może być w dużej mierze zautomatyzowana.
        Współpraca dystrybucyjna nie wymaga specjalnych kompetencji ani tak
        zwanego know-how. Dystrybutorami takimi mogą zostać na przykład:
      </p>
      <p>
        <ul>
          <li>pojedyncze sklepy,</li>
          <li>sieci sklepów,</li>
          <li>sieci franczyzowe,</li>
          <li>hurtownie.</li>
        </ul>
      </p>
      <br />
      <h3>Współpraca partnerska</h3>
      <p>
        Ze współpracą partnerską mamy do czynienia zazwyczaj wtedy, kiedy
        produkt lub usługa wymaga od strony sprzedającej odpowiedniego
        przygotowania merytorycznego. Partnerem w takim przypadku może zostać
        wyłącznie przedsiębiorstwo, które zdobędzie odpowiednie kompetencje i
        aktywnie poleca Twoją firmę, sprzedaje jej produkty albo nawet
        samodzielnie realizuje projekty bazujące na Twojej technologii lub
        licencji.
      </p>
      <section
      style={{
        backgroundColor: "rgb(244, 244, 244, 0.75)",
        color: "rgb(48, 48, 48)",
        marginTop: 30,
        marginBottom: 30
      }}
    >
      <div className="container container--text"
      >
        <h3 style={{
          paddingTop: 30,
          textAlign: "center",
        }}>Chcesz rozbudować kanał sprzedaży pośredniej?</h3>
        <p style={{
          textAlign: "center",
        }}
          >Napisz do nas, aby zyskać dostęp do bezpłatnej 30-dniowej wersji systemu CRM

</p>

      <div
            style={{
              textAlign: "center",
              margin: "20px",
              padding: "10px",
            }}
          >
            <Link className={styles.startNow} to="/kontakt/">
              Chcę wypróbować i rozwinąć swój proces sprzedażowy
            </Link>
          </div>
          </div>
    </section>
      <br />
      <h2>Charakterystyka sprzedaży pośredniej</h2>
      <p>
        <ul>
          <li>
            W tym modelu sprzedaży najważniejszym zadaniem działu handlowego
            jest pozyskiwanie partnerów biznesowych gotowych do stania się
            częścią Twojego kanału dystrybucyjnego.
          </li>
          <li>
            Podstawowym wyzwaniem, na jakie tutaj natrafią handlowcy, jest
            przekonanie potencjalnych partnerów do podjęcia aktywnej współpracy.
          </li>
          <li>
            Długoterminowym celem jest utrzymanie współpracy z pozyskanymi
            partnerami oraz systematyczne zwiększanie poziomu sprzedaży.
          </li>
          <li>
            W niektórych przypadkach może pojawić się dodatkowo konieczność
            pogodzenia kilku różnych kanałów sprzedażowych istniejących w Twojej
            firmie (na przykład kanału partnerskiego i wewnętrznego).
          </li>
        </ul>
      </p>
      <br />
      <Lightbox
        style={{
          maxWidth: 800,
          margin: "0 auto",
        }}
        images={[data.zdjecie3]}
        alts={["Budowa kanału sprzedaży pośredniej."]}
      />
      <br />
      <h2>Sprzedaż pośrednia — jak skutecznie zbudować kanał?</h2>
      <p>
        Najlepszym sposobem na odpowiedź na to pytanie jest przyjrzenie się
        temu, w jaki sposób tworzą się partnerskie relacje. Warto zacząć od
        rozrysowania procesu na kartce papieru. Zanotuj wszystkie działania,
        które musi wykonać handlowiec, aby pozyskać partnera i rozpocząć z nim
        współpracę. Następnie spróbuj pogrupować je etapami. Pozwala to później
        na łatwiejszą analizę ścieżki pozyskania sprzedawcy pośredniego i
        późniejsze jej optymalizowanie. Przykładowe etapy mogą wyglądać tak:
      </p>
      <p>
        <ul>
          <li>
            <strong>Uświadomienie</strong> - nawiązanie z danym podmiotem
            pierwszego kontaktu (lub jego odnowienie po dłuższym czasie). W tym
            momencie trzeba ustalić, czy współpraca jest korzystna dla obu
            stron. Na tym etapie ustala się również wstępne możliwe warunki
            współpracy, pozyskuje wszelkie przydatne informacje oraz klasyfikuje
            się potencjalnego partnera.
          </li>

          <li>
            <strong>Propozycja</strong> - tutaj następuje przyszykowanie
            sprecyzowanej oferty współpracy oraz przesłanie jej do akceptacji
            potencjalnemu partnerowi. Na tym etapie ustala się również wszelkie
            elementy, które są niezbędne do rozpoczęcia współpracy - na przykład
            odpowiednia infrastruktura czy instrukcje. Niezwykle istotne jest
            to, aby precyzyjnie określić, jakie obowiązki leżą po każdej ze
            stron, dzięki czemu unikniemy w przyszłości nieporozumień i
            konfliktów.
          </li>

          <li>
            <strong>Uruchomienie</strong> - na tym etapie następuje przekazanie
            wszystkich wymienionych powyżej elementów, a współpraca zostaje
            aktywowana. Efektem tego etapu może być, przykładowo, przesłanie
            pierwszej transzy towaru lub rozpoczęcie pierwszego wspólnego
            projektu.
          </li>
        </ul>
      </p>

      <p>
        Poza tym, w celu uniknięcia konfliktu interesów, warto wyznaczyć
        konkretną osobę (albo zespół) do zajmowania się kanałem partnerskim.
        Starajmy się nie dopuścić do sytuacji, w której ten sam pracownik ma w
        obowiązkach jednoczesne zajmowanie się zarówno sprzedażą do klientów
        końcowych jak i pośrednią. W takim układzie może dochodzić do konfliktu
        celów handlowca. Przykładem może być sytuacja, w której pozyskanie
        partnera biznesowego zwiększa poziom konkurencji o klienta i
        pracownikowi będzie trudniej osiągnąć własne cele sprzedażowe.
      </p>
      <br />
      <h2>Budowa kanału sprzedaży pośredniej - potencjalne problemy</h2>
      <p>
        Podczas budowania kanału sprzedaży pośredniej możesz natrafić na kilka
        problemów - zazwyczaj wiążących się z samym procesem pozyskania
        partnera. Podczas rozwijania swojej sieci partnerskiej możesz napotkać
        kilka problemów. Wiele z nich będzie dotyczyć samego procesu pozyskania.
        Mogą to być na przykład kwestie związane z atrakcyjnością samej oferty,
        a także ze sposobem jej dostarczenia. Bariery te znacznie utrudniają
        współpracę z nowymi podmiotami. Oto kilka przykładów problemów, na które
        możesz natrafić podczas budowania kanału pośredniej sprzedaży:
      </p>
      <p>
        <ul>
          <li>
            <strong>Bariera nieznajomości</strong> – problem ten może pojawić
            się wtedy, kiedy marka Twojego produktu nie jest szeroko znana na
            rynku. W takim układzie bardzo prawdopodobnym jest, że potencjalny
            partner o niej nie słyszał, a to znacznie utrudni przekonanie go do
            niej.
          </li>

          <li>
            <strong>Kłopot z uzyskaniem wiedzy o przyczynach odmowy</strong> -
            kiedy ktoś rezygnuje z partnerstwa, Twoja firma w żaden sposób nie
            przechowuje informacji o powodach takiej decyzji.
          </li>

          <li>
            <strong>Trudności negocjacyjne</strong> – ponieważ obie strony będą
            dążyły do ustalenia najbardziej korzystnych dla siebie warunków.
            Może to wywołać konflikt, który przyblokuje proces nawiązywania
            współpracy.
          </li>

          <li>
            <strong>Trudności z weryfikacją kompetencji partnera</strong> –
            ponieważ nie chcemy, aby ktoś nie znający naszych produktów mógł je
            polecać lub sprzedawać, powinniśmy przetestować jego know-how.
            Weryfikacja tej wiedzy może jednak być nie lada wyzwaniem.
          </li>

          <li>
            <strong>
              Problem równowagi pomiędzy sprzedażą bezpośrednią i pośrednią
            </strong>{" "}
            – te dwa kanały sprzedaży mogą się czasami pokrywać. Wtedy mogą
            wystąpić konflikty interesów między handlowcami a partnerami firmy.
          </li>
        </ul>
      </p>
      <br />
      <h2>Wsparcie systemu CRM w pozyskiwaniu partnerów</h2>
      <p>
        System CRM jest niezwykle przydatny podczas organizowania działań
        handlowych przedsiębiorstwa - również w procesie pozyskiwania firm do
        współpracy. Pomaga zarządzać relacjami z szeregiem podmiotów: od
        klientów końcowych, przez dystrybutorów aż po partnerów biznesowych. Do
        projektowania i zarządzania procesem budowy kanału sprzedaży pośredniej
        może posłużyć funkcja kampanii sprzedaży.
      </p>
      <br />
      <h2>Szanse sprzedaży - co to takiego?</h2>
      <p>
        Szanse sprzedaży są poręcznym narzędziem, dzięki któremu możesz w
        intuicyjny sposób zarządzać procesami sprzedażowymi i budowaniem relacji
        z kontrahentami. Umożliwiają ponadto bieżące monitorowanie tych
        procesów.
      </p>
      <br />
      <Lightbox
        style={{
          maxWidth: 800,
          margin: "0 auto",
        }}
        images={[data.zdjecie1]}
        alts={["Sekcja z zadaniami w aplikacji CRM."]}
      />
      <br />
      <h2>Zalety korzystania z szans sprzedaży</h2>
      <p>
        Dzięki nim możesz zbierać szeroki zakres informacji, które posłużą Ci do
        podjęcia optymalnych decyzji. Kampanie sprzedażowe pozwalają również na
        łatwą implementację jednolitego standardu pracy wśród handlowców. Poza
        tym, dzięki szansom sprzedaży:
      </p>
      <p>
        <ul>
          <li>
            <strong>Nie dublują się działania pracowników</strong> — nie będzie
            sytuacji, kiedy do jednego kontrahenta zgłosi się kilku handlowców,
            którzy nie widzą w systemie swoich wzajemnych działań.
          </li>

          <li>
            <strong>
              Nieobecni pracownicy mogą być łatwo i błyskawicznie zastąpieni
            </strong>
            , ponieważ wszystkie ważne informacje o danym kliencie i bieżącej
            transakcji są przechowywane w systemie i dostępne dla każdego
            użytkownika z odpowiednimi uprawnieniami.
          </li>

          <li>
            <strong>
              Handlowcy zawsze wiedzą, jaki powinien być następny krok
            </strong>{" "}
            względem danego partnera biznesowego — dzięki podpowiedziom w formie
            ikon.
          </li>

          <li>
            <strong>
              Kierownik ma łatwy wgląd na skumulowaną wiedzę o sukcesach oraz
              porażkach
            </strong>{" "}
            – te dane pozwalają mu szybko interweniować i usprawniać procesy
            sprzedażowe.
          </li>
        </ul>
      </p>
      <br />
      <h2>Sprzedaż pośrednia a CRM - optymalizowanie kanału partnerskiego</h2>
      <p>
        Uważamy, że wszystkie procesy biznesowe powinny być zaprojektowane tak,
        aby umożliwić firmie ciągłą naukę i optymalizację oraz znalezienie
        odpowiedzi na pytanie: dlaczego się nie udaje? Odpowiedź ta jest bowiem
        pierwszym krokiem do wprowadzenia ulepszeń i usunięcia ograniczeń.
      </p>

      <p>
        Jest to element, na który zwracamy szczególną uwagę, ponieważ sprowadza
        się do niego większość funkcji oprogramowania klasy CRM. Funkcje takie
        jak zadania, zdarzenia, szanse sprzedaży oraz baza kontrahentów
        udostępniają Ci całą masę informacji do analizowania. Mogą Ci one wiele
        powiedzieć o procesach zachodzących w firmie i o tym, jak zarządzają
        nimi Twoi pracownicy. System CRM pozwala dostrzec to, co nazywa się
        wąskimi gardłami oraz namierzyć największe słabości w samej ofercie.
      </p>
      <br />
      <Lightbox
        style={{
          maxWidth: 800,
          margin: "0 auto",
        }}
        images={[data.zdjecie2]}
        alts={["Raport aktywności handlowców w systemie CRM."]}
      />
      <br />
      <h3>W jaki sposób?</h3>
      <p>
        Jeśli cały proces jest zarządzany przez system CRM, wszystkie istotne
        zestawienia danych będą generowane w sposób automatyczny. Nie musisz
        tracić czasu na zbieranie i zestawianie informacji między sobą.
        Wszystko, co trzeba zrobić, to wybrać odpowiedni raport, a system zbiera
        i wyświetla potrzebne dane w postaci estetycznego zestawienia.
      </p>

      <p>
        Jeśli cały Twój proces będzie obsługiwany przez system CRM, wszystkie
        raporty będą generować się automatycznie. Nie musisz poświęcać swojego
        czasu na zbieranie i zestawianie danych ze sobą. Jedyne, co musisz
        zrobić, to wybrać raport, a system sam zbiera i wyświetla dane w formie
        estetycznego raportu.
      </p>
<section
      style={{
        backgroundColor: "rgb(244, 244, 244, 0.75)",
        color: "rgb(48, 48, 48)",
        marginTop: 30,
        marginBottom: 30
      }}
    >
      <div className="container container--text"
      >
        <h3 style={{
          paddingTop: 30,
          textAlign: "center",
        }}>Chcesz rozbudować kanał sprzedaży pośredniej?</h3>
        <p style={{
          textAlign: "center",
        }}
          >Napisz do nas, aby zyskać dostęp do bezpłatnej 30-dniowej wersji systemu CRM

</p>

      <div
            style={{
              textAlign: "center",
              margin: "20px",
              padding: "10px",
            }}
          >
            <Link className={styles.startNow} to="/kontakt/">
              Chcę wypróbować i rozwinąć swój proces sprzedażowy
            </Link>
          </div>
          </div>
    </section>
      <br />
      <br />
      <p>
        <ul>
          <li>
            <Link to="/aplikacja-crm-do-pracy-w-terenie">
              Aplikacja CRM: ułatwiona praca w terenie
            </Link>
          </li>
          <li>
            <Link to="/zarzadzanie-zespolem-marketingu-crm">
              Zarządzanie pracą zespołu marketingu w systemie CRM
            </Link>
          </li>
          <li>
            <Link to="/zalety-elastycznego-crm">
              Zalety elastycznego systemu CRM
            </Link>
          </li>
        </ul>
      </p>
    </Artykul>
  );
};

export default Aplikacjacrm;
