// extracted by mini-css-extract-plugin
export var featureList = "index-module--featureList--io0c3";
export var darkSection = "index-module--darkSection--2E8yp";
export var startNow2 = "index-module--startNow2--2m__e";
export var buttonWrapper = "index-module--button-wrapper--3cRbo";
export var container__functions = "index-module--container__functions--3Xsg5";
export var functioncard = "index-module--functioncard--1d5i4";
export var termsCard = "index-module--termsCard--uyoaB";
export var textWrapper = "index-module--textWrapper--2y2wm";
export var pricingSection = "index-module--pricingSection--1smQ0";
export var integrations = "index-module--integrations--3azwN";
export var integrations__wrapper = "index-module--integrations__wrapper--33p6a";
export var startNow = "index-module--startNow--292pA";
export var wrapper = "index-module--wrapper--2q246";
export var contactForm = "index-module--contactForm--3LFXP";
export var required = "index-module--required--juDmV";
export var allowProcessData = "index-module--allowProcessData--300Q4";
export var contactHeader = "index-module--contactHeader--1zCPc";
export var mapContainer = "index-module--mapContainer--2Y5F_";
export var phoneNum = "index-module--phoneNum--lq8q9";
export var formWrapper = "index-module--formWrapper--113gM";
export var processDataLabel = "index-module--processDataLabel--3230N";
export var tooltip = "index-module--tooltip--RL1Vl";
export var mobileDataProcess = "index-module--mobileDataProcess--2c-q8";